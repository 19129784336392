import React from 'react';
import EmailForm from '../components/EmailRegister';
import Layout from '../components/Layout';
import HeadLogo from '../images/head.svg'

const Subscribe = () => {
    return (<>
        <Layout>
            <div className='flex w-100 mx-auto justify-center mt-20 flex-col'>
                <img src={HeadLogo} alt='Mindful Sumo' className='h-64 mb-5' />
                <h1 className='uppercase text-6xl text-center leading-tight tracking-wide font-teko'>Subscribe To The <br/>MindFul Sumo<br/> Newsletter</h1>
                <EmailForm />
            </div>
        </Layout>
    </>);
}

export default Subscribe;